export enum QuestionType {
  SAQ = "SAQ",
  MCQ = "MCQ",
  EMQ = "EMQ",
  Flashcard = "Flashcard",
}

export type CMSQuestionBase = {
  title: number;
  name: string;
};

export type QuestionBase = {
  id: number;
  question: string;
};

export type CMSSaqQuestion = CMSQuestionBase & {
  question: string;
  answers: { answer: string }[];
};

export type SaqQuestion = QuestionBase & {
  answers: string[];
};

export type CMSMcqQuestion = CMSQuestionBase & {
  question: string;
  answer: string;
  incorrect: { answer: string }[];
  explanation: {
    text: string;
    more?: string;
    youtubeId?: string;
  };
};

export type McqQuestion = QuestionBase &
  Pick<CMSMcqQuestion, "explanation"> & {
    answers: string[];
  };

export type CMSEmqQuestion = CMSQuestionBase & {
  question: string;
  answer: string;
  incorrect: { answer: string }[];
};

export type EmqQuestion = QuestionBase & {
  answers: string[];
};

export type CMSQuestion = CMSSaqQuestion | CMSMcqQuestion | CMSEmqQuestion;
export type Question = SaqQuestion | McqQuestion | EmqQuestion;
export type Questions = SaqQuestion[] | McqQuestion[] | EmqQuestion[];
