import { WarningTwoIcon } from "@chakra-ui/icons";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useToast,
  Box,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { endpoints } from "@/constants";
import useConfig from "@/hooks/useConfig";
import Markdown from "./Markdown";

type FormValues = {
  description: string;
  reply?: string;
};

const ReportButton: React.FunctionComponent = () => {
  const [showReportModal, setShowReportModal] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const config = useConfig();
  const toast = useToast();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>();

  const maxReportLength = config?.maxReportLength ?? 160;

  const onFormSubmit: SubmitHandler<FormValues> = async (data, event) => {
    event?.preventDefault();

    setIsSending(true);

    await fetch(endpoints.report, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...data,
        url: window.location.pathname,
      }),
    });

    toast({
      title: "Feedback submitted",
      description: (
        <>
          It goes straight through to Tom for attention.
          <br />
          Thank you for helping to improve the site for everyone.
        </>
      ),
      position: "top",
      status: "success",
      duration: 8000,
      isClosable: true,
    });

    setIsSending(false);
    setShowReportModal(false);
    reset();
  };

  return (
    <>
      <Button as="button" onClick={() => setShowReportModal(true)} size="xs">
        <WarningTwoIcon position="relative" top="-1px" mr={1} />
        Feedback
      </Button>

      <Modal isOpen={showReportModal} onClose={() => setShowReportModal(false)}>
        <ModalOverlay />

        <ModalContent m={16}>
          <ModalHeader>
            <Heading as="h2" size="md">
              Feedback
            </Heading>
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody px={6} pb={6}>
            <Markdown>
              {`Please be specific in the issue you have found, for example,
                "typo in amitrypatline".\n\nYour feedback is linked to the page
                or question you have open so that the problem can be found
                and resolved quickly and easily.`}
            </Markdown>

            <Box as="form" mt={5} onSubmit={handleSubmit(onFormSubmit)}>
              <FormControl isInvalid={Boolean(errors.description)}>
                <FormLabel fontWeight="bold">Describe the issue</FormLabel>

                <Textarea
                  maxLength={maxReportLength}
                  {...register("description", {
                    required: "Description required",
                  })}
                />

                <FormErrorMessage>{errors?.description?.message}</FormErrorMessage>
              </FormControl>

              <Button type="submit" mt={5} colorScheme="blue" isDisabled={isSending}>
                Send
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ReportButton;
