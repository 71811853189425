import { QuestionType } from "@/types/question";
import { ValidationError } from "../types/error";

export { default as endpoints } from "./endpoints";

export const protectedPaths = [
  "/flashcards",
  "/how-to-learn-medicine",
  "/osce",
  "/quiz",
  "/tracker",
  "/results",
  "/fact-trainer",
];

// flashcards
export const LOCAL_STORAGE_KEY_FLASHCARDS_ACTIVE_CATEGORIES = "active-categories";
export const LOCAL_STORAGE_KEY_FLASHCARDS_RANDOMISE_CATEGORIES = "randomise-categories";

// fact trainer
export const LOCAL_STORAGE_KEY_FACT_TRAINER_CATEGORIES = "active-fact-trainer-categories";
export const LOCAL_STORAGE_KEY_ANSWERED_FACT_TRAINER_QUESTIONS = "answered-questions-fact-trainer";

// SAQ
export const LOCAL_STORAGE_KEY_SAQ_ACTIVE_CATEGORIES = "active-categories-saq";
export const LOCAL_STORAGE_KEY_SAQ_ACTIVE_QUESTIONS = "active-questions-saq";
export const LOCAL_STORAGE_KEY_SAQ_SKIPPED_SESSION_IDS = "active-skipped-saq";

// MCQ
export const LOCAL_STORAGE_KEY_MCQ_ACTIVE_CATEGORIES = "active-categories-mcq";
export const LOCAL_STORAGE_KEY_MCQ_ACTIVE_QUESTIONS = "active-questions-mcq";
export const LOCAL_STORAGE_KEY_MCQ_SKIPPED_SESSION_IDS = "active-skipped-mcq";

// EMQ
export const LOCAL_STORAGE_KEY_EMQ_ACTIVE_CATEGORIES = "active-categories-emq";
export const LOCAL_STORAGE_KEY_EMQ_ACTIVE_QUESTIONS = "active-questions-emq";
export const LOCAL_STORAGE_KEY_EMQ_SKIPPED_SESSION_IDS = "active-skipped-emq";

export const errorMessageMap: Record<ValidationError, string> = {
  [ValidationError.NoExamName]: "Exam name not provided",
  [ValidationError.ExamNotFound]: "Exam not found",
  [ValidationError.ExamNameTaken]: "Exam name already taken",
  [ValidationError.ExamNameMaxLength]: "Exam name exceeds max length",
  [ValidationError.MaxExamCountReached]: "Max exam count reached",
  [ValidationError.NoTopic]: "No topic selected",
  [ValidationError.InvalidTopic]: "One or more selected topics were not found",
  [ValidationError.MaxSessionsReached]: "Max session count reached for this topic",
  [ValidationError.MaxCustomTopicsReached]: "Max custom categories count reached",
  [ValidationError.InvalidCustomTopic]: "Custom topic does not exist for given exam",
  [ValidationError.InvalidConfidence]: "Invalid confidence value",
  [ValidationError.InvalidDate]: "Invalid date",
  [ValidationError.NotesLengthExceeded]: "Notes exceeds max length",
  [ValidationError.ExamResultCommentMaxLength]: "Comment exceeds max length",
  [ValidationError.InvalidExamResult]: "Invalid exam result value",
  [ValidationError.MaxQuizCountReached]: "Max quiz entries reached for current quiz type",
};

export const questionTypeContentDirectoryMap = new Map([
  [QuestionType.SAQ, "saq-questions"],
  [QuestionType.MCQ, "mcq-questions"],
  [QuestionType.EMQ, "emq-questions"],
]);

export const questionTypeCategoryKeyMap = new Map([
  [QuestionType.SAQ, "saqQuestions"],
  [QuestionType.MCQ, "mcqQuestions"],
  [QuestionType.EMQ, "emqQuestions"],
]);
