export enum ValidationError {
  NoExamName = "NoExamName",
  ExamNotFound = "ExamNotFound",
  ExamNameTaken = "ExamNameTaken",
  ExamNameMaxLength = "ExamNameMaxLength",
  MaxExamCountReached = "MaxExamCountReached",
  NoTopic = "NoTopic",
  InvalidTopic = "InvalidTopic",
  MaxSessionsReached = "MaxSessionsReached",
  InvalidCustomTopic = "InvalidCustomTopic",
  MaxCustomTopicsReached = "MaxCustomTopicsReached",
  InvalidConfidence = "InvalidConfidence",
  InvalidDate = "InvalidDate",
  NotesLengthExceeded = "NotesLengthExceeded",
  ExamResultCommentMaxLength = "ExamResultCommentMaxLength",
  InvalidExamResult = "InvalidExamResult",
  MaxQuizCountReached = "MaxQuizCountReached",
}
