import { Button } from "@chakra-ui/react";
import { signIn, signOut, useSession } from "next-auth/react";
import { useCallback } from "react";

const SignInOutLink: React.FC = () => {
  const { data: session } = useSession();

  const onSignOut = useCallback(() => {
    if (typeof window !== "undefined") {
      signOut({
        callbackUrl: window.location.origin,
      });
    }
  }, []);

  if (session) {
    return (
      <Button onClick={onSignOut} size="xs">
        Log out
      </Button>
    );
  }

  return (
    <Button onClick={() => signIn("patreon")} size="xs">
      Log in
    </Button>
  );
};

export default SignInOutLink;
