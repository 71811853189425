import { endpoints } from "@/constants";
import { Config } from "@/types/config";
import useSWR from "swr";

const useConfig = (): Config | undefined => {
  const { data } = useSWR<Config>(endpoints.getConfig);
  return data;
};

export default useConfig;
