import { QuestionType } from "@/types/question";

const flashcardsEndpoints = {
  getCards: "/api/flashcards/get-cards",
  getPinnedCardIds: "/api/flashcards/get-pinned-card-ids",
  getFlashcardsCategories: "/api/flashcards/get-categories",
  setFlashcardPin: "/api/flashcards/set-pin",
  deletePinnedFlashcards: "/api/flashcards/delete-pinned",
};

const quizEndpoints = {
  getAllQuizCategoriesByRoot: "/api/quiz/get-all-categories",
  deleteAttempt: (type: QuestionType, attemptId: number): string =>
    `/api/quiz/delete-attempt?type=${type.toLowerCase()}&id=${attemptId}`,
  answerQuestion: (type: QuestionType): string =>
    `/api/quiz/answer-question?type=${type.toLowerCase()}`,
  getQuestionIds: (type: QuestionType): string =>
    `/api/quiz/get-question-ids?type=${type.toLowerCase()}`,
  getQuestionById: (type: QuestionType, id: number): string =>
    `/api/quiz/get-question-by-id?type=${type.toLowerCase()}&id=${id}`,
  getQuizCategories: (type: QuestionType): string =>
    `/api/quiz/get-categories?type=${type.toLowerCase()}`,
  getAttempts: (type: QuestionType): string => `/api/quiz/get-attempts?type=${type.toLowerCase()}`,
  getCurrentAttempt: (type: QuestionType): string =>
    `/api/quiz/get-current-attempt?type=${type.toLowerCase()}`,
  createNewAttempt: (type: QuestionType): string =>
    `/api/quiz/create-new-attempt?type=${type.toLowerCase()}`,
};

const lessonEndpoints = {
  getLessons: "/api/lessons/get-all",
  getCompletedLessons: "/api/lessons/get-completed",
  setLessonComplete: "/api/lessons/set-completed",
  resetLessonsCompleted: "/api/lessons/reset-completed",
};

const examEndpoints = {
  getExams: "/api/exams/get-all",
  getExamById: (id: string) => `/api/exams/get-exam-by-id?id=${id}`,
  getSessionsByExamId: (id: string) => `/api/exams/get-sessions?id=${id}`,
  createExam: "/api/exams/create-exam",
  createExamSession: "/api/exams/create-session",
  updateExam: "/api/exams/update-exam",
  deleteExam: "/api/exams/delete-exam",
  deleteExamSession: "/api/exams/delete-session",
  upsertExamResult: "/api/exams/upsert-exam-result",
};

const osceEndpoints = {
  getOSCEStations: "/api/osce/get-stations",
  saveOsceScore: "/api/osce/save-score",
  getLatestOsceScores: "/api/osce/get-latest-scores",
  deleteOsceScores: "/api/osce/delete-scores",
};

const factTrainerEndpoints = {
  getFactTrainerS3Uri: "/api/fact-trainer/get-s3-uri",
  reviewFactTrainerQuestion: "/api/fact-trainer/review",
  deleteFactTrainer: "/api/fact-trainer/delete",
};

const endpoints = {
  report: "/api/report",
  getConfig: "/api/get-config",
  getNavigationLinks: "/api/get-navigation-links",
  getFaqs: "/api/get-faqs",
  getMembershipBenefits: "/api/get-membership-benefits",
  getHowToUseContent: (pageName: string): string =>
    `/api/get-how-to-use-content?pageName=${pageName}`,
  getCategories: "/api/get-categories",
  unansweredQuestion: "/api/unanswered-question",
  deleteAllData: "/api/delete-all-data",
  ...factTrainerEndpoints,
  ...flashcardsEndpoints,
  ...quizEndpoints,
  ...lessonEndpoints,
  ...examEndpoints,
  ...osceEndpoints,
};

export default endpoints;
