import { ChevronRightIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  IconButton,
  useDisclosure,
  VisuallyHidden,
} from "@chakra-ui/react";
import NextLink from "next/link";
import { useRef } from "react";
import ReportButton from "@/components/ReportButton";
import { NavigationLink, Breadcrumb as TBreadcrumb } from "@/types/navigation";
import { endpoints } from "@/constants";
import useSWR from "swr";
import styles from "./Header.module.css";
import Logo from "./Logo";
import Menu from "./Menu";
import SignInOutLink from "./SignInOutLink";

const defaultBreadcrumbs: TBreadcrumb[] = [
  {
    title: "Home",
    href: "/",
  },
];

type Props = {
  breadcrumbs?: TBreadcrumb[];
};

const Header: React.FC<Props> = ({ breadcrumbs = [] }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const mobileNavToggleRef = useRef<HTMLButtonElement>(null);
  const { data: links = [] } = useSWR<NavigationLink[]>(endpoints.getNavigationLinks);

  return (
    <header className={styles.root}>
      <Flex as="nav" h={81} py={4} color="#fff" fontWeight={700} backgroundColor="#023648">
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          w="100%"
          px={5}
          maxWidth={1200}
          margin="0 auto"
        >
          <NextLink href="/" passHref legacyBehavior>
            <Flex as="a" align="center">
              <VisuallyHidden>Home page</VisuallyHidden>
              <Logo height={34} />
            </Flex>
          </NextLink>

          <IconButton
            ref={mobileNavToggleRef}
            onClick={onOpen}
            display={[null, null, "none"]}
            colorScheme="blue"
            aria-label="Open navigation menu"
            icon={<HamburgerIcon />}
          />
          <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            finalFocusRef={mobileNavToggleRef}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton mt={1} />

              <DrawerBody>
                <Menu links={links} isInDrawer />
              </DrawerBody>
            </DrawerContent>
          </Drawer>

          <Box display={["none", null, "block"]} mx="auto">
            <Menu links={links} />
          </Box>
        </Flex>
      </Flex>

      <Box fontSize="0.875rem" bg="gray.50">
        <Flex
          flexWrap="nowrap"
          gap={4}
          alignItems="center"
          justifyContent="space-between"
          maxWidth={1200}
          w="100vw"
          margin="0 auto"
        >
          <Breadcrumb
            spacing={1}
            separator={<ChevronRightIcon color="gray.500" />}
            flex="1 1 auto"
            overflowX="auto"
            whiteSpace="nowrap"
            borderRight={["1px dashed rgba(0, 0, 0, .2)", null, "none"]}
            p={4}
          >
            {defaultBreadcrumbs.concat(breadcrumbs).map((breadcrumb, index) => {
              const { title, href } = breadcrumb;
              const isLastItem = breadcrumbs.length === index;

              return (
                // eslint-disable-next-line react/no-array-index-key
                <BreadcrumbItem key={index} isCurrentPage={isLastItem}>
                  {href ? (
                    <BreadcrumbLink
                      href={href}
                      fontWeight={isLastItem ? "bold" : undefined}
                      textDecoration="underline"
                      wordBreak="break-word"
                    >
                      {title}
                    </BreadcrumbLink>
                  ) : (
                    <Box as="span" wordBreak="break-word">
                      {title}
                    </Box>
                  )}
                </BreadcrumbItem>
              );
            })}
          </Breadcrumb>

          <Flex alignItems="center" gap={4} flex="0 0 auto" mr={3}>
            <Box display={["none", null, "flex"]}>
              <NextLink href="/faq" passHref legacyBehavior>
                <Button as="a" size="xs">
                  FAQ
                </Button>
              </NextLink>
            </Box>

            <ReportButton />

            <Box display={["none", null, "flex"]}>
              <SignInOutLink />
            </Box>
          </Flex>
        </Flex>
      </Box>
    </header>
  );
};

export default Header;
