import { NavigationLink } from "@/types/navigation";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Link } from "@chakra-ui/react";
import NextLink from "next/link";
import { isUrlAbsolute } from "@/server/url";
import styles from "./Header.module.css";
import SignInOutLink from "./SignInOutLink";

type MenuProps = {
  isInDrawer?: boolean;
  links: NavigationLink[];
};

const Menu: React.FC<MenuProps> = ({ isInDrawer, links = [] }) => (
  <Flex
    flexDirection={isInDrawer ? "column" : "row"}
    pt={isInDrawer ? 10 : 0}
    minHeight="100%"
    maxWidth={700}
  >
    <Flex
      as="ul"
      flexDirection={isInDrawer ? "column" : "row"}
      flexWrap="wrap"
      justifyContent="center"
    >
      {links.map(({ title, destination }, index) => (
        <Flex
          key={title}
          as="li"
          borderColor="gray.200"
          borderBottomWidth={isInDrawer && index < links.length - 1 ? 1 : undefined}
        >
          {isUrlAbsolute(destination) ? (
            <Box
              as={Link}
              href={destination}
              target="_blank"
              rel="noreferrer"
              isExternal
              className={styles.link}
              w={isInDrawer ? "100%" : "auto"}
              px={isInDrawer ? 3 : "15px"}
              py={isInDrawer ? 3 : "6px"}
            >
              {title}
              <ExternalLinkIcon
                position="relative"
                top="-1px"
                ml={isInDrawer ? 2 : 1}
                h={4}
                w={4}
                color={isInDrawer ? "rgba(0, 0, 0, .4)" : "rgba(255, 255, 255, .6)"}
              />
            </Box>
          ) : (
            <Link as={NextLink} href={destination} passHref legacyBehavior>
              <Box
                as="a"
                className={styles.link}
                w={isInDrawer ? "100%" : "auto"}
                px={isInDrawer ? 3 : "15px"}
                py={isInDrawer ? 3 : "6px"}
              >
                {title}
              </Box>
            </Link>
          )}
        </Flex>
      ))}
    </Flex>

    <Box display={isInDrawer ? "flex" : "none"} gap={3} mt="auto" pt={2} mb={3}>
      <NextLink href="/faq" passHref legacyBehavior>
        <Button as="a" size="xs">
          FAQ
        </Button>
      </NextLink>
      <SignInOutLink />
    </Box>
  </Flex>
);

export default Menu;
