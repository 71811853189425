import { Flex } from "@chakra-ui/react";
import { ClassNames } from "@emotion/react";

import Logo from "./Layout/Header/Logo";

const Loading: React.FunctionComponent = () => (
  <Flex
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    gap={6}
    position="absolute"
    top={0}
    right={0}
    bottom={0}
    left={0}
    m={6}
  >
    <ClassNames>
      {({ css }) => (
        <Logo
          dark
          className={css`
            @-webkit-keyframes text-blur-out {
              0% {
                filter: blur(0.01);
              }
              100% {
                filter: blur(12px) opacity(0%);
              }
            }

            @keyframes text-blur-out {
              0% {
                filter: blur(0.01);
              }
              100% {
                filter: blur(12px) opacity(0%);
              }
            }

            display: block;
            width: 100%;
            max-width: 500px;
            fill: black;

            animation: text-blur-out 1.2s cubic-bezier(0.55, 0.085, 0.68, 0.53) infinite
              alternate-reverse both;
          `}
        />
      )}
    </ClassNames>
    Please wait&hellip;
  </Flex>
);

export default Loading;
