import { Stack, Box, BoxProps } from "@chakra-ui/react";
import { useSession } from "next-auth/react";
import { ReactNode, FunctionComponent } from "react";
import { Breadcrumb } from "@/types/navigation";
import Loading from "../Loading";
import Header from "./Header";

type Props = BoxProps & {
  children: ReactNode;
  breadcrumbs?: Breadcrumb[];
};

const Layout: FunctionComponent<Props> = ({ children, breadcrumbs, ...props }) => {
  const { data: session, status } = useSession();

  if (status !== "loading" && !session) {
    return (
      <Box
        position="fixed"
        top={0}
        left={0}
        height="100%"
        width="100vw"
        backgroundColor="rgba(255, 255, 255, .85)"
      >
        <Loading />
      </Box>
    );
  }

  return (
    <Box
      display="grid"
      gridTemplateRows="auto 1fr"
      gridTemplateAreas={`
        "header"
        "content"
      `}
      minHeight="100vh"
      {...props}
    >
      <Header breadcrumbs={breadcrumbs} />

      <Stack
        as="main"
        pt={[6, null, 10]}
        pb={6}
        mx="auto"
        w="100%"
        maxWidth={1200}
        px={5}
        spacing={4}
        display="flex"
        gridArea="content"
        // https://stackoverflow.com/a/61960229
        minWidth={0}
      >
        <Box display="flex" flexDirection="column" flexGrow={1}>
          {children}
        </Box>
      </Stack>
    </Box>
  );
};

export default Layout;
